(function() {

  // reset diapo if click visite virtuelle
  document.addEventListener('change', function (e) {
    var item = e.target.closest('#visite0');
    if (!item) return;
    if(item.checked) {
      var media = document.querySelector('#entry-media_0');
      media.checked = true;
    }
  });

  document.addEventListener('change', function (e) {
    var item = e.target.closest('.modal-toggle');
    if (!item) return;
    var galerieInput = document.getElementById('galerie-bien');
    if(galerieInput) {
      galerieInput.checked = false;
    }
    document.body.classList.toggle('modal-zindex', item.checked);
  });

  document.addEventListener('change', function (e) {
    var item = e.target.closest('.change-zindex');
    if (!item) return;
    var cont = e.target.closest('.zindexplus');
    cont.classList.toggle('modal-opened', item.checked);
  });

  document.addEventListener('change', function (e) {
    var item = e.target.closest('name[galerie-bien]');
    if (!item) return;
    fullGalerieImages(document.querySelector('.galerie-bien'));
  });

  function fullGalerieImages(el) {
    var attr = 'data-src';
    if(!(el.querySelector('['+attr+']'))) return;
    var items = el.querySelectorAll('.galerie-item');
    for(var i = 0; i < items.length; i++) {
      var fullSrc = items[i].getAttribute(attr);
      items[i].querySelector('img').src = fullSrc;
      items[i].removeAttribute(attr);
    }
  }

})();